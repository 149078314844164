import { render, staticRenderFns } from "./originalOrders.vue?vue&type=template&id=05b4bd48&scoped=true&"
import script from "./originalOrders.vue?vue&type=script&lang=js&"
export * from "./originalOrders.vue?vue&type=script&lang=js&"
import style0 from "./originalOrders.vue?vue&type=style&index=0&id=05b4bd48&prod&scoped=true&lang=scss&"
import style1 from "./originalOrders.vue?vue&type=style&index=1&id=05b4bd48&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05b4bd48",
  null
  
)

export default component.exports